<template>
  <div>
    v1.7.3 (Commit c8ab652)
  </div>
</template>

<script>
export default {
  name: 'ShowVersion',
}
</script>

